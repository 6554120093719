import * as React from 'react'
import { GatsbyBrowser } from 'gatsby'
import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/gatsby'

import client from '@/components/ApolloClient'
import { UserProvider } from '@/providers/user'
import { MediaProvider } from '@/providers/media'

export const onClientEntry: GatsbyBrowser['onClientEntry'] = function () {
  window.Sentry = Sentry
}

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => (
  <ApolloProvider client={client}>
    <UserProvider>
      <MediaProvider>
        {element}
      </MediaProvider>
    </UserProvider>
  </ApolloProvider>
)

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
  const Layout = element.type.Layout ?? React.Fragment
  return <Layout {...props}>{element}</Layout>
}
