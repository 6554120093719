import { useState, useEffect } from 'react'

export const useViewportWidth = (): number => {
  const isSSR = () => typeof window === 'undefined'
  const [viewportWidth, setViewportWidth] = useState(!isSSR() ? window.innerWidth : 992)
  const toggleDesktop = () => setViewportWidth(window.innerWidth)
  useEffect(() => {
    if (!isSSR()) {
      toggleDesktop() // initial render
      window.addEventListener('resize', toggleDesktop)
      // clean up event listener
      return () => window.removeEventListener('resize', toggleDesktop)
    }
  })
  return viewportWidth
}
