import * as Sentry from "@sentry/gatsby";
import { environment } from "./src/env";

Sentry.init({
  environment: environment,
  enabled: environment !== 'development', 
  dsn: "https://10c2d396f4e2454099c7d848576d1c36@o289275.ingest.sentry.io/1526536",
  integrations: [new Sentry.Replay()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});