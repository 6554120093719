import React, { createContext, useContext, useEffect, useState } from 'react'
import atob from 'atob'
import useGetSession from '@/hooks/useGetSession'
import type { UserType } from '@/types/api/user'
import { environment } from '@/env'

const isBrowser = () => typeof window !== 'undefined'

const parseJWT = (jwt: string): UserType => {
  // atob doesn't support base64-url encoded data.
  const urlEncodedBase64 = jwt.split('.')[1];
  const base64 = urlEncodedBase64.replace(/-/g, '+').replace(/_/g, '/');
  let paddedBase64 = base64;
  while (paddedBase64.length % 4) {
    paddedBase64 += '=';
  }
  const jsonString = atob(paddedBase64);
  return JSON.parse(jsonString);
}

const getUserFromJwt = (): UserType | undefined => {
  const jwt = getJWT()
  if (!jwt) return undefined
  return parseJWT(jwt)
}

export const getJWT = (): string | null => isBrowser() ? window.localStorage.getItem('ls_jwt') : null

export const setJWT = (jwt: string | null): string | null => {
  if (jwt === null) {
    window.localStorage.removeItem('ls_jwt')
    return null
  }
  window.localStorage.setItem('ls_jwt', jwt)
  return jwt
}

type UserContextType = [
  UserType | undefined,
  (user?: UserType) => void
]

const UserContext = createContext<UserContextType>([
  getUserFromJwt(),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => { }
])

type UserProviderProps = {
  children: JSX.Element
}

export const UserProvider = ({ children }: UserProviderProps): JSX.Element => {
  const { data } = useGetSession()
  const [user, setUserObj] = useState<UserType | undefined>(getUserFromJwt())

  const setUser = (user?: UserType) => {
    if (user?.token) {
      setUserObj(user)
      setJWT(user.token)
      if (environment === 'production' && user.kustomerToken !== undefined) {
        window.Kustomer.login({ jwtToken: user.kustomerToken }, function (res: any, e: any) { console.info(res) });
      }
    } else {
      setUserObj(undefined)
      setJWT(null)
    }
  }

  useEffect(() => {
    if (data) setUser(data)
  }, [data])

  return (
    <UserContext.Provider value={[user, setUser]}>
      {children}
    </UserContext.Provider>
  )
}

export const useUserContext = (): UserContextType => useContext(UserContext)
