
import { ApolloClient, ApolloLink, from } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import fetch from 'isomorphic-fetch'
import env from '@/env'
import { getJWT, setJWT } from '@/providers/user'

enum ERROR_CODES {
  UNAUTHORIZED = "UNAUTHORIZED", // 401
  NOT_FOUND = "NOT_FOUND" // 404
}

const uploadLink = createUploadLink({ uri: `${env.API_URL}/graphql`, fetch })

const middlewareAuthLink = new ApolloLink((operation, forward) => {
  const token = getJWT()
  const authorization = token ? `Bearer ${token}` : null
  const headers: { authorization?: string } = {}
  if (authorization) {
    headers.authorization = authorization
  }
  operation.setContext({ headers })
  return forward(operation)
})

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors && graphQLErrors.length > 0) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    )
    for (const error of graphQLErrors) {
      if (error.extensions?.code === ERROR_CODES.UNAUTHORIZED) {
        setJWT(null)
        window.location.href = '/log-in/'
      }
    }
  }
});

export default new ApolloClient({
  link: from([errorLink, middlewareAuthLink, uploadLink]),
  cache: new InMemoryCache()
});
