import { useQuery } from '@apollo/client/react/hooks/useQuery'
import { UseQueryReturn } from '@/types'
import { UserType } from '@/types/api/user'
import { PROFILE_QUERY } from '@/queries/account'

type Data = {
  me: UserType
}

export default function useGetSession(): UseQueryReturn<UserType | null> {
  const { loading, data, error } = useQuery<Data>(PROFILE_QUERY, { fetchPolicy: 'network-only', ssr: false })
  return {
    state: loading ? 'loading' : error ? 'error' : data ? 'loaded' : 'empty',
    data: data?.me ?? null,
    error: error ? 'Invalid session' : undefined
  }
}
