// For CI build-specific environment variables, see .env files.
// These environment variables are available client-side.
type EnvironmentType = {
  readonly API_URL: string
  readonly HCAPTCHA_SITE_KEY: string
  readonly SQUARE_APPLICATION_ID: string
  readonly SQUARE_LOCATION_ID: string
  readonly SQUARE_SANDBOX: boolean
}

type EnvironmentDictionary = {
  readonly development: EnvironmentType
  readonly staging: EnvironmentType
  readonly production: EnvironmentType
}

const config: EnvironmentDictionary = {
  development: {
    API_URL: 'http://localhost:3000',
    HCAPTCHA_SITE_KEY: '10000000-ffff-ffff-ffff-000000000001',
    SQUARE_APPLICATION_ID: 'sandbox-sq0idb-0zWvnBNVoRVFu1dEIsYMpQ',
    SQUARE_LOCATION_ID: 'SJ6RCFY0MDKW6',
    SQUARE_SANDBOX: true
  },
  staging: {
    API_URL: 'https://staging-api.leatherspa.com',
    HCAPTCHA_SITE_KEY: '7e1430d8-49a9-4248-88c0-590ad7e29ee6',
    SQUARE_APPLICATION_ID: 'sandbox-sq0idb-0zWvnBNVoRVFu1dEIsYMpQ',
    SQUARE_LOCATION_ID: 'SJ6RCFY0MDKW6',
    SQUARE_SANDBOX: true
  },
  production: {
    API_URL: 'https://api.leatherspa.com',
    HCAPTCHA_SITE_KEY: '7e1430d8-49a9-4248-88c0-590ad7e29ee6',
    SQUARE_APPLICATION_ID: 'sq0idp-mDiIUNVfgpybsFBgyO9_8Q',
    SQUARE_LOCATION_ID: '082H0WFEEWRXH',
    SQUARE_SANDBOX: false
  }
}

export const environment = process.env.GATSBY_ENV || process.env.NODE_ENV || 'development'

export default config[environment] as EnvironmentType
