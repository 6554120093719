import React, { createContext, useContext } from 'react'
import { useViewportWidth } from '@/hooks/viewport'

const MediaContext = createContext({
  isDesktop: () => false
})

export const useMediaContext = () => useContext(MediaContext)

type MediaProviderProps = {
  children: JSX.Element
}

export const MediaProvider = ({ children }: MediaProviderProps) => {
  const viewportWidth = useViewportWidth()
  return (
    <MediaContext.Provider value={{ isDesktop: () => viewportWidth > 992 }}>
      {children}
    </MediaContext.Provider>
  )
}
