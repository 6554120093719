import gql from 'graphql-tag'

export const ADDRESS_QUERY = gql`
  query MyAddresses {
    myAddresses {
      id
      longitude
      latitude
      street
      addressLine2
      city
      state
      stateCode
      postalCode
      country
      countryCode
      type
      phoneNumber
      hasDoorman
      isResidential
    }
  }
`

export const PROFILE_QUERY = gql`
  query Profile {
    me {
      firstName
      lastName
      gender
      birthday
      favoriteBrand
      phoneNumber
      token
      kustomerToken
      addresses {
        id
        longitude
        latitude
        street
        addressLine2
        city
        state
        stateCode
        postalCode
        country
        countryCode
        type
      }
    }
  }
`
