exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account/[...].tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-covid-19-updates-index-tsx": () => import("./../../../src/pages/covid-19-updates/index.tsx" /* webpackChunkName: "component---src-pages-covid-19-updates-index-tsx" */),
  "component---src-pages-customer-services-corporate-tsx": () => import("./../../../src/pages/customer-services/corporate.tsx" /* webpackChunkName: "component---src-pages-customer-services-corporate-tsx" */),
  "component---src-pages-customer-services-delivery-tsx": () => import("./../../../src/pages/customer-services/delivery.tsx" /* webpackChunkName: "component---src-pages-customer-services-delivery-tsx" */),
  "component---src-pages-customer-services-feedback-tsx": () => import("./../../../src/pages/customer-services/feedback.tsx" /* webpackChunkName: "component---src-pages-customer-services-feedback-tsx" */),
  "component---src-pages-customer-services-index-tsx": () => import("./../../../src/pages/customer-services/index.tsx" /* webpackChunkName: "component---src-pages-customer-services-index-tsx" */),
  "component---src-pages-customer-services-payment-tsx": () => import("./../../../src/pages/customer-services/payment.tsx" /* webpackChunkName: "component---src-pages-customer-services-payment-tsx" */),
  "component---src-pages-customer-services-pick-up-tsx": () => import("./../../../src/pages/customer-services/pick-up.tsx" /* webpackChunkName: "component---src-pages-customer-services-pick-up-tsx" */),
  "component---src-pages-customer-services-virtual-repair-tsx": () => import("./../../../src/pages/customer-services/virtual-repair.tsx" /* webpackChunkName: "component---src-pages-customer-services-virtual-repair-tsx" */),
  "component---src-pages-forgot-password-index-tsx": () => import("./../../../src/pages/forgot-password/index.tsx" /* webpackChunkName: "component---src-pages-forgot-password-index-tsx" */),
  "component---src-pages-golocker-index-tsx": () => import("./../../../src/pages/golocker/index.tsx" /* webpackChunkName: "component---src-pages-golocker-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-licensed-stores-index-tsx": () => import("./../../../src/pages/licensed-stores/index.tsx" /* webpackChunkName: "component---src-pages-licensed-stores-index-tsx" */),
  "component---src-pages-locations-index-tsx": () => import("./../../../src/pages/locations/index.tsx" /* webpackChunkName: "component---src-pages-locations-index-tsx" */),
  "component---src-pages-log-in-index-tsx": () => import("./../../../src/pages/log-in/index.tsx" /* webpackChunkName: "component---src-pages-log-in-index-tsx" */),
  "component---src-pages-partnerships-index-tsx": () => import("./../../../src/pages/partnerships/index.tsx" /* webpackChunkName: "component---src-pages-partnerships-index-tsx" */),
  "component---src-pages-repair-services-index-tsx": () => import("./../../../src/pages/repair-services/index.tsx" /* webpackChunkName: "component---src-pages-repair-services-index-tsx" */),
  "component---src-pages-repair-wizard-index-tsx": () => import("./../../../src/pages/repair-wizard/index.tsx" /* webpackChunkName: "component---src-pages-repair-wizard-index-tsx" */),
  "component---src-pages-sign-up-index-tsx": () => import("./../../../src/pages/sign-up/index.tsx" /* webpackChunkName: "component---src-pages-sign-up-index-tsx" */),
  "component---src-pages-start-a-repair-index-tsx": () => import("./../../../src/pages/start-a-repair/index.tsx" /* webpackChunkName: "component---src-pages-start-a-repair-index-tsx" */),
  "component---src-pages-sustainability-index-tsx": () => import("./../../../src/pages/sustainability/index.tsx" /* webpackChunkName: "component---src-pages-sustainability-index-tsx" */),
  "component---src-pages-ups-store-index-tsx": () => import("./../../../src/pages/ups-store/index.tsx" /* webpackChunkName: "component---src-pages-ups-store-index-tsx" */),
  "component---src-templates-customer-services-page-tsx": () => import("./../../../src/templates/customer-services-page.tsx" /* webpackChunkName: "component---src-templates-customer-services-page-tsx" */),
  "component---src-templates-location-page-tsx": () => import("./../../../src/templates/location-page.tsx" /* webpackChunkName: "component---src-templates-location-page-tsx" */),
  "component---src-templates-policy-page-tsx": () => import("./../../../src/templates/policy-page.tsx" /* webpackChunkName: "component---src-templates-policy-page-tsx" */),
  "component---src-templates-press-page-tsx": () => import("./../../../src/templates/press-page.tsx" /* webpackChunkName: "component---src-templates-press-page-tsx" */),
  "component---src-templates-repair-item-page-tsx": () => import("./../../../src/templates/repair-item-page.tsx" /* webpackChunkName: "component---src-templates-repair-item-page-tsx" */),
  "component---src-templates-repair-service-page-tsx": () => import("./../../../src/templates/repair-service-page.tsx" /* webpackChunkName: "component---src-templates-repair-service-page-tsx" */),
  "component---src-templates-testimonial-page-tsx": () => import("./../../../src/templates/testimonial-page.tsx" /* webpackChunkName: "component---src-templates-testimonial-page-tsx" */)
}

